<template>
  <div style="display: flex; column-gap: 10px">
    <p class="text-hoverable text--primary" @click="$router.go(-1)">{{ backName }}</p>
    <p class="text--primary">/</p>
    <p class="text-hoverable text--primary">{{ currentName }}</p>
  </div>
</template>

<script>
export default {
  props: {
    currentName: '',
    backName: '',
  }
}
</script>

<style>

</style>