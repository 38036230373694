<template>
  <div class="image-backdrop" @click="$emit('close')" tabindex="0" ref="modal" @keydown.esc="$emit('close')">
    <img class="fullscreen-image" :src="src" :alt="alt">
  </div>
</template>

<script>
export default {
  props: {
    src: '',
    alt: '',
  },
  mounted() {
    this.$refs.modal.focus()
  }
}
</script>

<style>
.image-backdrop {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.568);
}

.fullscreen-image {
  min-width: none;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  padding: 25px;
  box-sizing: border-box;
}
</style>