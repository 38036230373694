<template>
  <content-sheet>
    <article class="news-article large-width-padding">
      <div class="news-header">
        <back-navigation :currentName="(title[+($i18n.locale !== 'sv')] || $t('news.missing'))" :backName="$t('news.image-header')" style="margin-bottom: 20px" />
      </div>

      <div v-if="images.length > 1" class="image-slider"> 
        <div class="carousel-nav" @click="$refs.slider.$emit('slidePre')"><PrevIcon /></div>
        <slider ref="slider" :options="options" > 
          <slideritem v-for="(image, index) in images" :key="index" >
            <div class="image-container" @mouseenter="showExpand = true" @mouseleave="showExpand = false">
              <img :src="image" class="image-slider-item">
              <transition name="fade">
                <div v-if="showExpand" class="expand-icon" @click="selectImage(image)"><ExpandIcon /></div>
              </transition>
            </div>
          </slideritem>
        </slider>
        <div class="carousel-nav" @click="$refs.slider.$emit('slideNext')"><NextIcon /></div>
      </div>
      <div v-else-if="images.length == 1" style="display: flex; justify-content: center; width: 100%">
        <div class="image-container" @mouseenter="showExpand = true" @mouseleave="showExpand = false">
          <img :src="images[0]" class="image-slider-item">
          <transition name="fade">
            <div v-if="showExpand" class="expand-icon" @click="selectImage(images[0])"><ExpandIcon /></div>
          </transition>
        </div>
      </div>

      <div class="news-header">
        <h1>{{ (title[+($i18n.locale !== 'sv')] || $t('news.missing')) }}</h1>
        <p style="text-transform: capitalize; font-weight: lighter;">{{ date }}</p>
      </div>
      <div class="news-article-content" v-html="content[+($i18n.locale !== 'sv')]"></div>

      <fullscreen-image-view v-if="showModal" :src="selectedImageURL" @close="showModal = false"/>
    </article>
  </content-sheet>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import ContentSheet from '../ContentSheet.vue'
import BackNavigation from '../BackNavigation.vue'
import ExpandIcon from '../../assets/icons/expand.svg'
import NextIcon from '../../assets/icons/forward.svg'
import PrevIcon from '../../assets/icons/back.svg'

import { slider, slideritem } from 'vue-concise-slider'
import FullscreenImageView from '../FullscreenImageView.vue'


export default {
  data() {
    return {
      selectedImageURL: '',
      showModal: false,
      showExpand: false,
    }
  },
  props: {
    title: '',
    content: '',
    date: '',
    images: [],
    options: {},
  },
  methods: {
    selectImage(url) {
      this.selectedImageURL = url
      this.showModal = true
    }
  },
  components: {
    slider,
    slideritem,
    FullscreenImageView,
    ExpandIcon,
    NextIcon,
    PrevIcon,
    ContentSheet,
    BackNavigation
  }
}
</script>

<style>

.news-article {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* max-width: 1000px;
  width: 90%;
  margin: 0 auto; */
  /* background-color: white; */
  /* padding: 50px 20px; */
  /* padding-bottom: 50px; */
  /* margin: 0 auto; */
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, 0.075); */
  /* box-sizing: border-box; */
}

.news-article-content {
  width: 100%;
  padding: 0 50px;
  margin-top: 20px;
  box-sizing: border-box;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.news-header {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0 50px;
}

.news-article .news-header h1 {
  margin: 0;
  letter-spacing: normal;
  text-transform: none;
}

.news-article > div > p {
  font-size: 20px;
  margin: 0;
}

.news-article > div > h1 {
  font-size: max(2.3vw, 25px);
  letter-spacing: normal;
  text-transform: none;
  margin: 0;
}
.news-article > div > h2 {
  font-size: max(2vw, 26px);
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0.05em;
  margin: 0;
}
.news-article > div > h3 {
  font-size: 24px;
  letter-spacing: 0.05em;
  text-transform: none;
  margin: 0;
}

.ql-align-right {text-align: right;}
.ql-align-center {text-align: center;}
.ql-align-left {text-align: left;}
.ql-align-justify {text-align: justify;}

.image-slider {
  display: flex; 
  justify-content: center; 
  align-items: center; 
  width: 60%;
  /* width: 440px; */
  margin: 20px auto;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.image-slider-item {
  min-width: none;
  width: 400px;
  height: 300px;
  object-fit: cover;
}
.image-container {
  position: relative;
  width: 400px;
  height: 300px;
  margin-bottom: 30px;
}
.expand-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255); 
  border-radius: 5px;
  position: absolute; 
  top: 0; 
  right: 0px;
  background-color: rgba(0, 0, 0, 0.39);
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.expand-icon:hover {
  background-color: rgba(0, 0, 0, 0.514);

}

.carousel-nav {
  width: 30px;
  height: 30px;

  font-size: 30px;
  color: black;
  /* border-radius: 15px; */
  cursor: pointer;
  /* background-color: rgba(0, 0, 0, 0.13); */
}
.carousel-nav:hover {
  opacity: 0.6;
}

.slider-pagination-bullet {
  background: #000000 none repeat scroll 0 0 !important;
}

.slider-pagination-bullet-active {
  background: #e5b409 none repeat scroll 0 0  !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 200ms;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media screen and (max-width: 900px) {

  .image-slider {
    width: 90%;
  }
}

@media screen and (max-width: 700px) {
  .news-article-content {
    padding: 0;
  }
  .news-header {
    margin: 0;
  }
  .image-slider {
    width: 100%;
  }
  .image-slider-item {
    min-width: none;
    width: 260px;
    height: 220px;
    object-fit: cover;
  }
  .image-container {
    position: relative;
    width: 260px;
    height: 220px;
    padding-bottom: 10px;
  }
}

</style>